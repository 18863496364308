
































import { Component, Vue } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'

@Component({
  components: {
    LayoutCompact
  }
})
export default class Home extends Vue {
  public result: string = 'success'
}
